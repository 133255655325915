import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import BASE_URL from "../config";

Modal.setAppElement("#root");

const RescheduleModal = ({ appointmentId, doctorId, isOpen, onClose }) => {
  const [date, setDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchTimeSlots = async (selectedDate) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`${BASE_URL}/admin/doctor/${doctorId}/schedules`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const matchedSchedule = response.data.find((schedule) => schedule.date === selectedDate);

      const availableTimeSlots = matchedSchedule
        ? matchedSchedule.TimeSlots.filter((slot) => slot.is_available)
        : [];

      setTimeSlots(availableTimeSlots);
    } catch (err) {
      setError("Failed to fetch time slots. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleReschedule = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/appointments/${appointmentId}/reschedule`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newDate: date,
          newTimeSlotId: selectedSlot,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to reschedule appointment.");
      }

      alert("Appointment rescheduled successfully!");
      onClose();
    } catch (err) {
      setError(err.message || "Failed to reschedule appointment.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Reschedule Appointment"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Use a percentage for mobile responsiveness
          maxWidth: "500px", // Limit the width on larger screens
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "auto", // Handle overflow content
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
        },
      }}
    >
      <h3
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
        className="font-bold border-b-2 border-green-500"
      >
        Reschedule Appointment
      </h3>
      <div style={{ marginBottom: "20px" }}>
        <label style={{ display: "block", marginBottom: "8px", fontWeight: "bold" }}>
          Select Date:
        </label>
        <input
          type="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
            fetchTimeSlots(e.target.value);
          }}
          style={{
            width: "100%",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <label style={{ display: "block", marginBottom: "8px", fontWeight: "bold" }}>
          Select Time Slot:
        </label>
        <select
          value={selectedSlot}
          onChange={(e) => setSelectedSlot(e.target.value)}
          disabled={loading || !timeSlots.length}
          style={{
            width: "100%",
            padding: "10px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        >
          <option value="" disabled>
            {loading
              ? "Loading..."
              : timeSlots.length
              ? "Select a time slot"
              : "No slots available"}
          </option>
          {timeSlots.map((slot) => (
            <option key={slot.id} value={slot.id}>
              {slot.start_time} - {slot.end_time}
            </option>
          ))}
        </select>
      </div>
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <button
          onClick={onClose}
          className="font-bold border-b-2 border-green-500 text-gray-500"
        >
          Cancel
        </button>
        <button
          onClick={handleReschedule}
          disabled={!date || !selectedSlot || loading}
          className="bg-green-500 text-white px-4 py-2 mt-2 rounded"
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default RescheduleModal;
