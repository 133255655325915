import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import Swal from "sweetalert2";
import { FaDownload } from "react-icons/fa"; // Importing the download icon
import BASE_URL from "../../config";
import { customFetch } from "../../api";

const DoctorAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState({
    totalPatients: 0,
    totalAppointments: 0,
    upcomingAppointments: 0,
    completedAppointments: 0,
  });
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user.id) {
          Swal.fire("Error", "User ID is not available.", "error");
          return;
        }

        const data = await customFetch(
          `${BASE_URL}/analytics/doctor-analytics?doctorId=${user.id}`
        );
        setAnalyticsData(data);
      } catch (error) {
        Swal.fire("Error", "Failed to fetch analytics data", "error");
        console.error("Error fetching analytics data:", error);
      }
    };

    fetchAnalytics();
  }, []);

  const chartData = {
    labels: ["Total Patients", "Total Appointments", "Upcoming", "Completed"],
    datasets: [
      {
        label: "Total Patients",
        data: [analyticsData.totalPatients, 0, 0, 0],
        backgroundColor: "#42a5f5",
      },
      {
        label: "Total Appointments",
        data: [0, analyticsData.totalAppointments, 0, 0],
        backgroundColor: "#66bb6a",
      },
      {
        label: "Upcoming",
        data: [0, 0, analyticsData.upcomingAppointments, 0],
        backgroundColor: "#AF937B",
      },
      {
        label: "Completed",
        data: [0, 0, 0, analyticsData.completedAppointments],
        backgroundColor: "#470A12",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14, // Adjust font size for legend labels
            family: "Arial", // Customize the font family
          },
          color: "#333", // Set the color of the legend text
        },
      },
      background: {
        beforeDraw: (chart) => {
          const ctx = chart.canvas.getContext("2d");
          ctx.save();
          ctx.globalCompositeOperation = "destination-over";
          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    },
  };

  // Function to download the chart as an image
  const downloadChart = () => {
    const chartInstance = chartRef.current;
    if (chartInstance) {
      const base64Image = chartInstance.toBase64Image();
      const link = document.createElement("a");
      link.href = base64Image;
      link.download = "doctor-analytics-chart.png";
      link.click();
    }
  };

  return (
    <div className="bg-luxwhite p-4 rounded-lg shadow-lg relative">
      <h2 className="text-2xl font-bold font-headers text-darkgreen mb-4">
        Analytics
      </h2>

      {/* Download Icon in the top-right corner */}
      <div
        onClick={downloadChart}
        className="absolute top-4 right-4 cursor-pointer"
      >
        <FaDownload size={24} color="#000" />
      </div>

      <Bar ref={chartRef} data={chartData} options={chartOptions} />
    </div>
  );
};

export default DoctorAnalytics;
