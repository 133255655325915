import React, { useState, useEffect } from "react";
import BASE_URL from "../../config";
import { FaEye } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Pagination from "../Pagination";
import { customFetch } from "../../api";

const ManageBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [amountPaid, setAmountPaid] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [refetch, setRefetchStatus] = useState(false);
  const [filters, setFilters] = useState({
    date: "",
    status: "",
    bookingFor: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const data = await customFetch(`${BASE_URL}/bookings`);
        setBookings(data);
        setTotalPages(Math.ceil(data.length / 3));
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [refetch]);

  const handleSelectBooking = (booking) => {
    setSelectedBooking(booking);
    setAmountPaid("");
    setPaymentStatus("");
  };

  const handleUpdatePayment = async (billingId) => {
    try {
      // Update payment status
      await customFetch(`${BASE_URL}/bookings/billings/${billingId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount_paid: parseFloat(amountPaid),
          payment_status: paymentStatus,
        }),
      });
  
      if (selectedBooking.Billing.Invoices && selectedBooking.Billing.Invoices.length > 0) {
        const invoiceId = selectedBooking.Billing.Invoices[0].invoice_id;
  
        await customFetch(`${BASE_URL}/bookings/invoices/${invoiceId}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            amount_due: parseFloat(amountPaid),
          }),
        });
      }
  
      setSelectedBooking(null);
      setRefetchStatus(true);
      toast.success("Payment status and invoice updated successfully!");
    } catch (error) {
      console.error("Error updating payment status and invoice:", error);
    }
  };
  

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const filteredBookings = bookings.filter((booking) => {
    return (
      (filters.date ? booking.bookingDate.includes(filters.date) : true) &&
      (filters.status ? booking.bookingStatus === filters.status : true) &&
      (filters.bookingFor
        ? booking.bookingFor.toLowerCase().includes(filters.bookingFor.toLowerCase())
        : true)
    );
  });

  const bookingsToDisplay = filteredBookings.slice((page - 1) * 2, page * 2);

  useEffect(() => {
    // Recalculate total pages whenever bookings or filters change
    setTotalPages(Math.ceil(filteredBookings.length / 2));
  }, [filteredBookings]);

  return (
    <div className="bg-luxwhite p-4 rounded-lg shadow-lg">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <h2 className="text-2xl font-headers font-bold text-darkgreen mb-4">
        Manage Bookings
      </h2>

      {/* Filters */}
      {!selectedBooking && (
        <div className="mb-4">
          <div className="flex gap-4 mb-4">
            <div className="flex flex-col w-full">
              <label className="mb-2 font-semibold text-sm" htmlFor="bookingFor">
                Booking For
              </label>
              <input
                type="text"
                name="bookingFor"
                placeholder="Filter by Booking For"
                value={filters.bookingFor}
                onChange={handleFilterChange}
                className="p-2 border rounded"
              />
            </div>
          </div>

          <div className="flex gap-4">
            <div className="flex flex-col w-1/2">
              <label className="mb-2 font-semibold text-sm" htmlFor="date">
                Date
              </label>
              <input
                type="date"
                name="date"
                value={filters.date}
                onChange={handleFilterChange}
                className="p-2 border rounded"
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="mb-2 font-semibold text-sm" htmlFor="status">
                Status
              </label>
              <select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                className="p-2 border rounded"
              >
                <option value="">Select Status</option>
                <option value="confirmed">Confirmed</option>
                <option value="canceled">Canceled</option>
                <option value="rescheduled">Rescheduled</option>
              </select>
            </div>
          </div>
        </div>
      )}


      {/* Booking List */}
      {selectedBooking ? (
        <div className="modal">
          <div className="modal-content">
            

            <div className="tabs mt-4 mb-6">
              <button
                onClick={() => setActiveTab("details")}
                className={`py-2 px-4 font-semibold focus:outline-none ${
                  activeTab === "details"
                    ? "border-green-500 border-b-2 text-green-600" : "text-gray-500"
                }`}
              >
                Booking Details
              </button>
              <button
                onClick={() => setActiveTab("payment")}
                className={`py-2 px-4 font-semibold focus:outline-none ${
                  activeTab === "payment"
                    ? "border-green-500 border-b-2 text-green-600" : "text-gray-500"
                }`}
              >
                Payment Information
              </button>
            </div>

            {activeTab === "details" && (
              <div>
                <p><strong>Booking For:</strong> {selectedBooking.bookingFor}</p>
                <p><strong>Contact No:</strong> {selectedBooking.contactNo}</p>
                <p><strong>Email:</strong> {selectedBooking.email}</p>
                <p><strong>Booking Date:</strong> {new Date(selectedBooking.bookingDate).toLocaleString()}</p>
                <p><strong>Status:</strong> {selectedBooking.bookingStatus}</p>
              </div>
            )}

            {activeTab === "payment" && (
              <div className="mt-4 border-t pt-2">
                {selectedBooking.Billing ? (
                  <div>
                    <p><strong>Amount Paid:</strong> ${selectedBooking.Billing.amount_paid.toFixed(2)}</p>
                    <p><strong>Payment Status:</strong> {selectedBooking.Billing.payment_status}</p>
                    <p><strong>Invoice Date:</strong> {new Date(selectedBooking.Billing.invoice_date).toLocaleString()}</p>

                    {/* Invoice details (if available) */}
                    {selectedBooking.Billing.Invoices && selectedBooking.Billing.Invoices.length > 0 ? (
                      <div className="mt-2">
                        <h4 className="font-semibold">Invoice Details:</h4>
                        {selectedBooking.Billing.Invoices.map((invoice) => (
                          <div key={invoice.invoice_id}>
                            <p><strong>Amount Due:</strong> ${invoice.amount_due.toFixed(2)}</p>
                            <p><strong>Invoice Date:</strong> {new Date(invoice.invoice_date).toLocaleString()}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>No invoices available for this booking.</p>
                    )}

                    {/* Update payment section */}
                    <h4 className="font-bold mt-2">Update Payment</h4>
                    <input
                      type="number"
                      placeholder="Amount Paid"
                      value={amountPaid}
                      onChange={(e) => setAmountPaid(e.target.value)}
                      className="p-2 border rounded mb-2 w-full"
                    />
                    <select
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                      className="p-2 border rounded mb-2 w-full"
                    >
                      <option value="">Select Status</option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                    
                    {/* Button aligned to the right */}
                    <div className="flex justify-end">
                      <button
                        onClick={() => handleUpdatePayment(selectedBooking.Billing.billing_id)}
                        className="bg-green-500 text-luxwhite py-2 px-6 rounded-lg hover:bg-green-600 transition"
                      >
                        Update Payment
                      </button>
                    </div>
                  </div>
                ) : (
                  <p>No billing information available for this booking.</p>
                )}
              </div>
            )}



            <div className="flex justify-between mt-4">
              <button
                onClick={() => setSelectedBooking(null)}
                className="font-bold border-b-2 border-green-500 text-gray-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : (
        <ul>
          {bookingsToDisplay.map((booking) => (
            <li key={booking.id} className="border-b py-2 flex justify-between">
              <div>
                <p><strong>Booking For:</strong> {booking.bookingFor}</p>
                <p><strong>Date:</strong> {new Date(booking.bookingDate).toLocaleString()}</p>
                <p><strong>Status:</strong> {booking.bookingStatus}</p>
              </div>
              <span
                onClick={() => handleSelectBooking(booking)}
                className="flex items-center text-indigo-600 cursor-pointer"
              >
                <FaEye className="mr-2" />
                View
              </span>
            </li>
          ))}
        </ul>
      )}

      {/* Pagination */}
      {!selectedBooking && totalPages > 1 && (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={(page) => handlePageChange(page)}
        />
      )}

      

    </div>
  );
};

export default ManageBookings;
