import React, { useState, useEffect } from "react";
import Popup from "../Popup";
import BASE_URL from "../../config";
import { FaTrash, FaEdit } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Pagination from "../Pagination";
import { api } from "../../api";

const TreatmentGalleryManagement = ({ profileId }) => {
  const [activeTab, setActiveTab] = useState("upload");
  const [beforePreview, setBeforePreview] = useState(null);
  const [afterPreview, setAfterPreview] = useState(null);
  const [beforeImage, setBeforeImage] = useState(null);
  const [afterImage, setAfterImage] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  // Helper function to strip '/api' from BASE_URL for image display
  const stripApiFromBaseUrl = (url) => url.replace("/api", "");

  useEffect(() => {
    if (activeTab === "gallery") {
      fetchUploadedImages();
    }
  }, [activeTab]);

  const handlePreview = () => setIsPopupVisible(true);

  const handleImageUpload = (event, setImage, setPreview) => {
    const file = event.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async () => {
    if (!beforeImage || !afterImage) {
      alert("Please upload both before and after images.");
      return;
    }

    const formData = new FormData();
    formData.append("profileId", profileId);
    formData.append("before", beforeImage);
    formData.append("after", afterImage);

    try {
      await api.post(
        `${BASE_URL}/image-uploads/upload-transformation`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("Images uploaded successfully!");
      setBeforeImage(null);
      setAfterImage(null);
      setBeforePreview(null);
      setAfterPreview(null);
      fetchUploadedImages(); // Refresh the gallery
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Failed to upload images. Please try again.");
    }
  };


  // Dropzone configuration for both images
  const { getRootProps: getBeforeRootProps, getInputProps: getBeforeInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        handleImageUpload(
          { target: { files: acceptedFiles } },
          setBeforeImage,
          setBeforePreview,
          "before"
        );
      }
    },
    accept: "image/*",
  });

  const { getRootProps: getAfterRootProps, getInputProps: getAfterInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        handleImageUpload(
          { target: { files: acceptedFiles } },
          setAfterImage,
          setAfterPreview,
          "after"
        );
      }
    },
    accept: "image/*",
  });

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchUploadedImages();
  }, []);

  const fetchUploadedImages = async () => {
    try {
      const response = await api.get( `${BASE_URL}/image-uploads/transformations`);
      setUploadedImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (imageId) => {
    if (!window.confirm("Are you sure you want to delete this transformation?")) return;

    try {
      await api.delete( `${BASE_URL}/image-uploads/transformations/${imageId}`);
      setUploadedImages((prevImages) => prevImages.filter((img) => img.id !== imageId));
      toast.success("Images deleted successfully!");
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Failed to delete the image.");
    }
  };

  const handleReplace = async (imageId, imageType) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";

    fileInput.onchange = async () => {
      if (fileInput.files.length === 0) return;

      const formData = new FormData();
      formData.append("image", fileInput.files[0]);
      formData.append("imageType", imageType);

      try {
        await api.put( `${BASE_URL}/image-uploads/transformations/${imageId}/replace`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        fetchUploadedImages();
        toast.success(`${imageType} image replaced successfully.`);
      } catch (error) {
        console.error("Error replacing image:", error);
        toast.error("Failed to replace the image.");
      }
    };

    fileInput.click();
  };

  if (loading) {
    return <p>Loading gallery...</p>;
  }

  // Images for the current page
  const currentImages = uploadedImages.slice((currentPage - 1) * 3, currentPage * 3);


  return (
    <div className="bg-luxwhite p-6 rounded-lg shadow-lg space-y-6">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <h2 className="text-2xl font-headers font-bold text-darkgreen">Treatment Gallery Management</h2>

      {/* Tab Navigation */}
      <div className="flex border-b border-gray-300">
        <button
          className={`py-2 px-4 font-semibold focus:outline-none ${
            activeTab === "upload"
              ? "border-green-500 border-b-2 text-green-600" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("upload")}
        >
          Upload Images
        </button>
        <button
          className={`py-2 px-4 font-semibold focus:outline-none ${
            activeTab === "gallery"
              ? "border-green-500 border-b-2 text-green-600" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("gallery")}
        >
          View Gallery
        </button>
      </div>

      {/* Upload Tab */}
      {activeTab === "upload" && (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              {/* Before Image Upload */}
              <div className="upload-area">
                <label className="block text-darkgreen font-semibold mb-2">Upload Before Image</label>
                <div
                  {...getBeforeRootProps()}
                  className="border-dashed border-2 border-lightbrown p-4 rounded-lg text-center cursor-pointer"
                >
                  <input {...getBeforeInputProps()} />
                  <span className="text-lightbrown">Drag & drop or click to upload</span>
                </div>
              </div>
              {beforePreview && (
                <img
                  src={beforePreview}
                  alt="Before Preview"
                  className="mt-4 w-full max-w-xs object-contain"
                />
              )}
            </div>
            <div>
              {/* After Image Upload */}
              <div className="upload-area">
                <label className="block text-darkgreen font-semibold mb-2">Upload After Image</label>
                <div
                  {...getAfterRootProps()}
                  className="border-dashed border-2 border-lightbrown p-4 rounded-lg text-center cursor-pointer"
                >
                  <input {...getAfterInputProps()} />
                  <span className="text-lightbrown">Drag & drop or click to upload</span>
                </div>
              </div>
              {afterPreview && (
                <img
                  src={afterPreview}
                  alt="After Preview"
                  className="mt-4 w-full max-w-xs object-contain"
                />
              )}
            </div>
          </div>

          {/* Buttons aligned to the right */}
          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={handlePreview}
              className="bg-lightbrown text-luxwhite py-2 px-6 rounded-lg hover:bg-lightgreen transition"
            >
              Preview
            </button>
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-luxwhite py-2 px-6 rounded-lg hover:bg-green-600 transition"
            >
              Submit
            </button>
          </div>

          {/* Popup */}
          <Popup
            isVisible={isPopupVisible}
            title="Preview Before and After Images"
            message={
              <div className="flex flex-col items-center">
                {beforePreview && (
                  <img
                    src={beforePreview}
                    alt="Before Preview"
                    className="mb-4 max-w-xs object-contain"
                  />
                )}
                {afterPreview && (
                  <img
                    src={afterPreview}
                    alt="After Preview"
                    className="max-w-xs object-contain"
                  />
                )}
              </div>
            }
            onClose={() => setIsPopupVisible(false)}
          />
        </div>
      )}


      {/* Gallery Tab */}
      {activeTab === "gallery" && (
      <div>
        <h3 className="text-xl font-bold text-darkgreen">Uploaded Images</h3>

        {/* Image Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
          {currentImages.length > 0 ? (
            currentImages.map((image) => (
              <div key={image.id} className="relative">
                {/* Before Image */}
                <div className="mb-4 relative">
                  <img
                    src={`${stripApiFromBaseUrl(BASE_URL)}${image.before}`}
                    alt={`Before ${image.id}`}
                    className="w-full h-auto rounded-lg"
                  />
                  <button
                    onClick={() => handleReplace(image.id, "before")}
                    className="absolute top-2 left-2 bg-blue-500 text-white p-1 rounded-full"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDelete(image.id)}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                  >
                    <FaTrash />
                  </button>
                  <p className="text-center text-sm text-gray-500">Before</p>
                </div>

                {/* After Image */}
                <div className="mb-4 relative">
                  <img
                    src={`${stripApiFromBaseUrl(BASE_URL)}${image.after}`}
                    alt={`After ${image.id}`}
                    className="w-full h-auto rounded-lg"
                  />
                  <button
                    onClick={() => handleReplace(image.id, "after")}
                    className="absolute top-2 left-2 bg-blue-500 text-white p-1 rounded-full"
                  >
                    <FaEdit />
                  </button>
                  <p className="text-center text-sm text-gray-500">After</p>
                </div>
              </div>
            ))
          ) : (
            <p>No images uploaded yet.</p>
          )}
        </div>



        {/* Pagination */}

        {uploadedImages.length > 2 && (
          <Pagination
            currentPage={currentPage}
            totalPages={uploadedImages.length}
            onPageChange={(currentPage) => setCurrentPage(currentPage)}
          />
        )}
      </div>
    )}

    </div>
  );
};

export default TreatmentGalleryManagement;
