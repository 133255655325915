import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="bg-darkgreen text-luxwhite font-paragraph min-h-screen p-6">
      <div className="max-w-4xl mx-auto space-y-8">
        {/* Header */}
        <header className="text-center">
          <h1 className="text-h1 font-headers text-luxwhite">Terms and Conditions</h1>
        </header>

        {/* Introduction */}
        <section>
          <h2 className="text-h2 font-headers text-almond">1. Introduction</h2>
          <p>
            Welcome to Revival Medical Aesthetics ("we," "us," "our"). By creating an account on
            our platform and using our website or mobile application (collectively, the
            "Platform"), you agree to these Terms and Conditions. Please read them carefully.
          </p>
        </section>

        {/* Eligibility */}
        <section>
          <h2 className="text-h2 font-headers text-almond">2. Eligibility</h2>
          <p>
            You must be 18 years or older to create an account and use the Platform. By registering,
            you confirm that the information you provide is accurate, current, and complete.
          </p>
        </section>

        {/* Use of the Platform */}
        <section>
          <h2 className="text-h2 font-headers text-almond">3. Use of the Platform</h2>
          <p>
            The Platform is intended for appointment booking, viewing medical procedure summaries,
            payment history, and making payments for services. You agree not to misuse the Platform,
            including but not limited to attempting unauthorized access or interfering with the
            functionality of the Platform.
          </p>
        </section>

        {/* Appointments and Cancellations */}
        <section>
          <h2 className="text-h2 font-headers text-almond">4. Appointments and Cancellations</h2>
          <p>
            Appointments can be booked through the Platform, subject to availability. Cancellations
            or rescheduling must be done at least 24 hours before the scheduled appointment. Late
            cancellations may incur a fee.
          </p>
        </section>

        {/* Payments */}
        <section>
          <h2 className="text-h2 font-headers text-almond">5. Payments</h2>
          <p>
            Payments for services can be made through the Platform using credit cards. Outstanding
            balances must be settled before further appointments are scheduled. Payment information
            is processed securely via third-party payment processors; we do not store your credit
            card information.
          </p>
        </section>

        {/* Marketing Communications */}
        <section>
          <h2 className="text-h2 font-headers text-almond">6. Marketing Communications</h2>
          <p>
            By creating an account, you consent to receive marketing communications from us via
            email, SMS, or other channels. You may opt out at any time by updating your preferences
            in your account settings or contacting us directly.
          </p>
        </section>

        {/* Liability */}
        <section>
          <h2 className="text-h2 font-headers text-almond">7. Liability</h2>
          <p>
            While we strive to provide accurate and up-to-date information, the Platform is provided
            "as is" without warranties of any kind. To the extent permitted by law, we disclaim all
            liability for any damages resulting from the use of the Platform, including data
            breaches.
          </p>
        </section>

        {/* Data Breaches */}
        <section>
          <h2 className="text-h2 font-headers text-almond">8. Data Breaches and Security</h2>
          <p>
            We implement industry-standard measures to protect your data. However, no system is
            entirely secure. In the unlikely event of a data breach, we will notify affected users as
            required by law and take necessary steps to mitigate harm.
          </p>
        </section>

        {/* Footer */}
        <footer className="text-center mt-8">
          <p>Contact us at: revivalsandton@icloud.com | +27 71 036 2777</p>
        </footer>
      </div>
    </div>
  );
};

export default TermsAndConditions;
