import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BASE_URL from "../../config";
import { customFetch } from "../../api";

const MySwal = withReactContent(Swal);

const TestimonialUpload = () => {
  const [testimonial, setTestimonial] = useState("");
  const [image, setImage] = useState(null);
  const [dragActive, setDragActive] = useState(false);

  const handleTestimonialChange = (e) => {
    setTestimonial(e.target.value);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImage(e.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleFileSelect = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAreaClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!testimonial) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please provide both a testimonial and an image.",
      });
      return;
    }

    const formData = new FormData();
    formData.append("testimonial", testimonial);
    formData.append("image", image);

    try {
      await customFetch(`${BASE_URL}/testimonials/upload`, {
        method: "POST",
        body: formData,
      });

      MySwal.fire({
        icon: "success",
        title: "Thank you!",
        text: "Your testimonial has been submitted successfully.",
      });
      setTestimonial("");
      setImage(null);
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: "Upload Failed",
        text: "There was an error submitting your testimonial. Please try again.",
      });
    }
  };

  return (
    <div className="bg-white p-4 rounded shadow-md">
      <h2 className="text-2xl font-headers font-semibold mb-4">
        Submit Your Testimonial
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <textarea
          placeholder="Write your testimonial here..."
          value={testimonial}
          onChange={handleTestimonialChange}
          className="w-full p-2 border border-gray-300 rounded"
          rows="3"
          required
        />
        <div
          className={`border-2 border-dashed ${
            dragActive ? "border-blue-500" : "border-gray-300"
          } rounded p-4 text-center cursor-pointer`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleFileDrop}
          onClick={handleAreaClick}
        >
          {image ? (
            <p>{image.name}</p>
          ) : (
            <p>Click or drag and drop an image here</p>
          )}
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            onChange={handleFileSelect}
            className="hidden"
          />
        </div>
        <button
          type="submit"
          className="bg-lightbrown font-cta text-luxwhite py-2 px-4 rounded hover:bg-blue-600"
        >
          Submit Testimonial
        </button>
      </form>
    </div>
  );
};

export default TestimonialUpload;
