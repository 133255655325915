import { jwtDecode } from "jwt-decode";

const getTrimmedTokenAndExpiry = (token) => {
  try {
    // Decode the token to get the expiration date
    const decoded = jwtDecode(token);
    const expirationDate = new Date(decoded.exp * 1000); // Convert to milliseconds

    // Trim the token to show the first 10 characters and the last 10 characters
    const trimmedToken = `${token.slice(0, 10)}...${token.slice(-10)}`;

    return { trimmedToken, expirationDate };
  } catch (error) {
    console.error("Error decoding token:", error);
    return { trimmedToken: "Invalid Token", expirationDate: null };
  }
};

export default getTrimmedTokenAndExpiry;
