import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import BASE_URL from "../../config";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { api } from "../../api";
import { customFetch } from "../../api";

const Notifications = () => {
  const [message, setMessage] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sendToAll, setSendToAll] = useState(true);
  const [activeTab, setActiveTab] = useState("send");
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const currentUserId = JSON.parse(localStorage.getItem("user"))?.id;

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        // Fetch notifications from the API
        const data = await customFetch(`${BASE_URL}/notifications`, {});

        const apiMessages = data.map((notification) => ({
          id: notification.notifications_id,
          subject: notification.message.substring(0, 20) + "...",
          body: notification.message,
          sentAt: "New",
        }));

        // Fake message for testing
        const fakeMessages = [
          {
            id: 2,
            subject: "New Features Released",
            body: "We have added new features to improve your experience. Check them out!",
            sentAt: "2025-01-05 3:30 PM",
          },
        ];

        // Combine API messages with fake messages
        setMessages([...apiMessages, ...fakeMessages]);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [currentUserId]);


  // Function to handle sending notifications immediately
  const handleSendNow = async () => {
    if (!message || (!sendToAll && selectedUsers.length === 0)) {
      Swal.fire("Error", "Please write a message and select users.", "error");
      return;
    }
  
    try {
      const response = await api.post(`${BASE_URL}/notifications`, {
        message,
        userIds: sendToAll ? [] : selectedUsers,
        created_by: currentUserId,
      });
      if (response.status === 200) {
        toast.success("Notification Sent!");
        setMessage("");
        setSelectedUsers([]);
        setUsers([])
        setSendToAll(false);
      }
    } catch (error) {
      Swal.fire("Error", "Failed to send notification.", "error");
    }
  };

  // Function to search for users
  const handleUserSearch = async () => {
    if (userSearch) {
      try {
        const response = await api.get(
          `${BASE_URL}/notifications/users?search=${userSearch}`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error searching for users:", error);
      }
    }
  };

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg relative z-50">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

      {/* Tabs */}
      <div className="flex border-b mb-6">
        <button
          className={`px-4 py-2 focus:outline-none ${
            activeTab === "send" ? "border-green-500 border-b-2 text-green-600" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("send")}
        >
          Send Notification
        </button>
        <button
          className={`px-4 py-2 focus:outline-none ${
            activeTab === "inbox" ? "border-green-500 border-b-2 text-green-600" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("inbox")}
        >
          Inbox
        </button>
      </div>

      {activeTab === "send" && (
        <div>
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Send Notification</h2>
          {/* Recipients Section */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Recipients</label>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="sendToAll"
                name="recipientType"
                checked={sendToAll}
                onChange={() => {
                  setSendToAll(true);
                  setSelectedUsers([]);
                }}
                className="h-4 w-4 text-green-600 border-gray-300"
              />
              <label htmlFor="sendToAll" className="ml-2 text-sm text-gray-800">
                Send to All Users
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="radio"
                id="sendToSpecific"
                name="recipientType"
                checked={!sendToAll}
                onChange={() => setSendToAll(false)}
                className="h-4 w-4 text-green-600 border-gray-300"
              />
              <label htmlFor="sendToSpecific" className="ml-2 text-sm text-gray-800">
                Send to Specific Users
              </label>
            </div>
            {!sendToAll && (
              <div className="mt-4">
                <input
                  type="text"
                  value={userSearch}
                onChange={(e) => {
                  setUserSearch(e.target.value);
                  handleUserSearch();
                }}
                  placeholder="Search for users..."
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                <div className="mt-2 max-h-40 overflow-y-auto border border-gray-300 rounded-lg p-2">
                  {users.map((user) => (
                    <div key={user.id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        value={user.id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers((prev) => [...prev, user.id]);
                          } else {
                            setSelectedUsers((prev) => prev.filter((id) => id !== user.id));
                          }
                        }}
                        className="h-4 w-4 text-green-600 border-gray-300"
                      />
                      <label className="ml-2 text-sm text-gray-700">{user.email}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Message Section */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Write your message..."
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              rows="4"
            ></textarea>
          </div>

          {/* Send Button */}
          <div className="flex justify-end">
            <button
              onClick={handleSendNow}
              className="bg-green-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-green-600 transition-all"
            >
              Send Notification
            </button>
          </div>
        </div>
      )}

      {activeTab === "inbox" && (
        <div>
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Inbox</h2>
          <div className="max-h-96 overflow-y-auto border border-gray-300 rounded-lg">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className="p-4 border-b cursor-pointer hover:bg-gray-100"
                onClick={() => setSelectedMessage(msg)}
              >
                <h3 className="font-semibold text-gray-800">{msg.subject}</h3>
                <p className="text-sm text-gray-600">{msg.sentAt}</p>
              </div>
            ))}
          </div>

          {/* Message Details Modal */}
          {selectedMessage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
              onClick={() => setSelectedMessage(null)}
            >
              <div
                className="bg-white p-6 rounded-lg shadow-lg w-96 relative"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={() => setSelectedMessage(null)}
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full h-8 w-8 flex items-center justify-center hover:bg-red-600"
                >
                  ✕
                </button>
                <h3 className="text-lg font-bold text-gray-800">{selectedMessage.subject}</h3>
                <p className="text-sm text-gray-600 mb-4">{selectedMessage.sentAt}</p>
                <p className="text-gray-700 mb-6">{selectedMessage.body}</p>
                <button
                  onClick={() => setSelectedMessage(null)}
                  className="font-bold border-b-2 border-green-500 text-gray-500"
                >
                  Close
                </button>
              </div>
            </div>
          )}

        </div>
      )}
    </div>
  )
};
  

export default Notifications;
