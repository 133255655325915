import React, { useState, useEffect } from "react";
import { FaEye, FaExpand, FaCompress } from "react-icons/fa";
import BASE_URL from "../../config";
import MedicalRecordsModal from "../MedicalRecordsModal";
import Pagination from "../Pagination";
import { customFetch } from "../../api";

const PatientInfo = () => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedView, setExpandedView] = useState(false);

  const itemsPerPage = 3;

  const fetchPatients = async () => {
    try {
      const data = await customFetch(
        `${BASE_URL}/patients/patients?searchTerm=${searchTerm}`
      );
      setPatients(data);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  useEffect(() => {
    fetchPatients();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const toggleExpandedView = () => {
    setExpandedView((prev) => !prev);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    fetchPatients();
  };

  const paginatedPatients = patients.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className={`p-4 shadow-lg rounded-lg bg-luxwhite ${expandedView ? "w-full" : "max-w-md"}`}>
      <MedicalRecordsModal selectedPatient={selectedPatient} isOpen={isModalOpen} onClose={closeModal} />
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-darkgreen font-headers mb-4">Patient Information</h2>
        <button onClick={toggleExpandedView} className="text-gray-600 hover:text-gray-800">
          {expandedView ? <FaCompress size={20} /> : <FaExpand size={20} />}
        </button>
      </div>

      <input
        type="text"
        placeholder="Search by name or condition"
        className="w-full p-2 mb-4 border rounded-lg"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <ul>
        {paginatedPatients.length === 0 ? (
          <li>No patients found matching your search criteria.</li>
        ) : (
          paginatedPatients.map((patient) => (
            <li key={patient.id} className="border-b py-4">
              <p className="mb-1">
                <strong>Patient:</strong> {patient.name}
              </p>
              <p className="mb-1">
                    <strong>Condition:</strong>{" "}
                    {patient.medicalRecords[0]?.diagnosis ||
                      "No condition listed"}
              </p>
              <textarea
                className="w-full p-2 border rounded-lg mb-2"
                rows={3}
                defaultValue={patient.medicalRecords[0]?.notes || "No notes available"}
                readOnly
              ></textarea>
              <button
                onClick={() => {
                  setSelectedPatient(patient);
                  setIsModalOpen(true);
                }}
                className="text-blue-500 hover:underline flex items-center gap-2"
              >
                <FaEye /> Medical Records
              </button>
            </li>
          ))
        )}
      </ul>

      <Pagination
        currentPage={currentPage}
        totalPages={patients.length}
        onPageChange={(page) => setCurrentPage(page)}
      />

    </div>
  );
};

export default PatientInfo;
