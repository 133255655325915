import React, { useState, useEffect } from "react";
import BASE_URL from "../../config";
import Switch from "react-switch"; // Import react-switch
import { Tooltip } from "react-tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { customFetch } from "../../api";

const WebsiteSettings = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [halfMaintenanceMode, setHalfMaintenanceMode] = useState(false);
  const [acceptPayments, setAcceptPayments] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await customFetch(`${BASE_URL}/maintenance`);
        setMaintenanceMode(data.full_maintenance);
        setHalfMaintenanceMode(data.half_maintenance);
        setAcceptPayments(data.accept_payments);
      } catch (error) {
        console.error("Error fetching maintenance settings:", error);
      }
    };
  
    fetchSettings();
  }, []);

  const updateSettings = async (updates) => {
    try {
      await customFetch(`${BASE_URL}/maintenance/toggle`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updates),
      });
  
      toast.success("Settings updated successfully!");
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("An error occurred while updating settings.");
    }
  };
  
  const toggleMaintenanceMode = () => {
    updateSettings({ full_maintenance: !maintenanceMode });
    setMaintenanceMode((prev) => !prev);
  };
  
  const toggleHalfMaintenanceMode = () => {
    updateSettings({ half_maintenance: !halfMaintenanceMode });
    setHalfMaintenanceMode((prev) => !prev);
  };
  
  const toggleAcceptPayments = () => {
    updateSettings({ accept_payments: !acceptPayments });
    setAcceptPayments((prev) => !prev);
  };
  

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <h2 className="text-h3 font-bold font-headers text-darkgreen mb-6">
        Website Settings
      </h2>

      <div className="mb-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold text-darkgreen mb-2 flex items-center gap-2">
            Full Maintenance Mode
            <span
              className="text-blue-600 cursor-pointer"
              data-tooltip-id="full-maintenance-tooltip"
              data-tooltip-content="This will disable the entire site for users, but admins can still log in."
            >
              <FaQuestionCircle size={18} />
            </span>
          </h3>
          <Tooltip id="full-maintenance-tooltip" place="top" effect="solid" />
          <p className="text-gray-700">
            {maintenanceMode ? "ON" : "OFF"}
          </p>
        </div>
        <Switch
          onChange={toggleMaintenanceMode}
          checked={maintenanceMode}
          offColor="#767577"
          onColor="#81b0ff"
          offHandleColor="#f4f3f4"
          onHandleColor="#58d68d"
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          className="react-switch"
        />
      </div>

      <div className="mb-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold text-darkgreen mb-2 flex items-center gap-2">
            Half Maintenance Mode
            <span
              className="text-blue-600 cursor-pointer"
              data-tooltip-id="half-maintenance-tooltip"
              data-tooltip-content="This allows doctors and admins to log in, but users will be blocked."
            >
              <FaQuestionCircle size={18} />
            </span>
          </h3>
          <Tooltip id="half-maintenance-tooltip" place="top" effect="solid" />
          <p className="text-gray-700">
            {halfMaintenanceMode ? "ON" : "OFF"}
          </p>
        </div>
        <Switch
          onChange={toggleHalfMaintenanceMode}
          checked={halfMaintenanceMode}
          offColor="#767577"
          onColor="#81b0ff"
          offHandleColor="#f4f3f4"
          onHandleColor="#58d68d"
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          className="react-switch"
        />
      </div>

      <div className="mb-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg font-semibold text-darkgreen mb-2 flex items-center gap-2">
            Accept Payments
            <span
              className="text-blue-600 cursor-pointer"
              data-tooltip-id="payment-tooltip"
              data-tooltip-content="Enable payments acceptance via the website."
            >
              <FaQuestionCircle size={18} />
            </span>
          </h3>
          <Tooltip id="payment-tooltip" place="top" effect="solid" />
          <p className="text-gray-700">
            {acceptPayments ? "ON" : "OFF"}
          </p>
        </div>
        <Switch
          onChange={toggleAcceptPayments}
          checked={acceptPayments}
          offColor="#767577"
          onColor="#81b0ff"
          offHandleColor="#f4f3f4"
          onHandleColor="#58d68d"
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          className="react-switch"
        />
      </div>

      <div className="flex justify-end">
        <button
          onClick={toggleMaintenanceMode}
          className="bg-green-500 text-luxwhite py-2 px-6 rounded-lg hover:bg-green-600 transition"
        >
          Save Settings
        </button>
      </div>
    </div>
  );
};

export default WebsiteSettings;
