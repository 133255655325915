import React, { useState, useEffect } from "react";
import Header from "../components/Includes/Header";
import Footer from "../components/Includes/Footer";
import ManageUsers from "../components/AdminDashboard/ManageUsers";
import ManageSchedule from "../components/AdminDashboard/ManageSchedule";
import TreatmentGalleryManagement from "../components/AdminDashboard/TreatmentGalleryManagement";
import AnalyticsOverview from "../components/AdminDashboard/AnalyticsOverview";
import ManageBookings from "../components/AdminDashboard/ManageBookings";
import Notifications from "../components/DoctorDashboard/Notifications";
import WebsiteSettings from "../components/AdminDashboard/WebsiteSettings";
import ManageTestimonials from "../components/AdminDashboard/ManageTestimonials";
import Pagination from "../components/Pagination";

const AdminDashboard = () => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFullWidth, setIsFullWidth] = useState(false);

  // Simulated components for pagination
  const components = [
    <AnalyticsOverview key="analytics" />,
    <ManageUsers key="manage-users" />,
    <ManageSchedule key="manage-schedule" />,
    <Notifications key="notifications" />,
    <TreatmentGalleryManagement key="treatment-gallery" />,
    <ManageBookings key="manage-bookings" />,
    <ManageTestimonials key="manage-testimonials" />,
    <WebsiteSettings key="website-settings" />
  ];

  const itemsPerPage = 3; // Number of components per page
  const totalPages = Math.ceil(components.length / itemsPerPage);

  

  // Get paginated components
  const getPaginatedComponents = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return components.slice(startIndex, startIndex + itemsPerPage);
  };

  const [selectedMenu, setSelectedMenu] = useState(null); // Track the selected menu
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Track if sidebar is open or closed
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);



  const menuItems = [
    { id: 2, name: "Users" },
    { id: 3, name: "Schedule" },
    { id: 6, name: "Bookings" },
    { id: 7, name: "Settings" },
    { id: 1, name: "Analytics" },
    { id: 4, name: "Notif" },
    { id: 8, name: "Testimony" },
    { id: 5, name: "Treatment Gallery" }
  ];

  const itemsToPageMapping = {
    1: [1, 2, 3],  // Page 1 has items 1, 2, 3
    2: [4, 5, 6],  // Page 2 has items 4, 5, 6
    3: [7, 8]      // Page 3 has items 7, 8
  };

  const handleMenuClick = (id) => {
    setSelectedMenu(id);
    let page = 1;
    // Find which page this item belongs to
    for (const [pageNumber, items] of Object.entries(itemsToPageMapping)) {
      if (items.includes(id)) {
        page = parseInt(pageNumber);
        break;
      }
    }
    setCurrentPage(page); // Set the page number based on the clicked menu item
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar visibility
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close sidebar on mobile view when the page loads or on resize
  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }else {
      const timer = setTimeout(() => setShowWelcome(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [isMobile]);

  return (
    <div className="bg-almond">
        {/* White rectangle with menu options */}
        <div
          className={`absolute top-0 left-0 w-[120px] min-h-screen bg-white z-10 p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}
        >
          <ul className="space-y-4">
            {menuItems.map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => handleMenuClick(item.id)}
                  className={`w-full text-left py-2 px-4 font-semibold ${selectedMenu === item.id ? 'text-green-500 border-b-2 border-green-500' : 'text-gray-700'}`}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      <Header />

      <main className={`${isFullWidth ? "" : "container"} mx-auto px-4 py-8 md:pt-[100px] pt-[60px] min-h-screen bg-almond`}>
        {/* Welcome Section */}
        {showWelcome && (
          <section className="bg-brown-200 rounded-lg shadow-lg p-6 md:p-8 mb-6 lg:mb-8 text-center lg:text-left transition-all duration-300">
            <h1 className="md:text-3xl lg:text-h2 font-bold font-headers text-darkgreen">
              Welcome to the Admin Dashboard
            </h1>
          </section>
        )}

        {/* Full-Width Toggle */}
        {/* <div className="flex justify-end mb-4">
          <button
            onClick={() => setIsFullWidth(!isFullWidth)}
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-green-600 transition-all"
          >
            {isFullWidth ? "Exit Full Width" : "Full Width View"}
          </button>
        </div> */}

        {/* Paginated Components */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8" style={{ height: "calc(100vh - 170px)", overflowY: "auto" }}>
          {getPaginatedComponents()}
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </main>

      <Footer />
    </div>
  );
};

export default AdminDashboard;
