import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { AuthContext } from "../../context/AuthContext";
import BASE_URL from "../../config";
import { customFetch } from "../../api";

const PersonalInfo = () => {
  const { auth } = useContext(AuthContext);

  const [userInfo, setUserInfo] = useState({
    firstName: auth.user.profile.firstName,
    lastName: auth.user.profile.lastName,
    email: auth.user.email,
    nationality: auth.user.profile.nationality,
    birthday: auth.user.profile.birthday.split("T")[0],
    gender: auth.user.profile.gender,
    emergencyContactName: auth.user.profile.emergencyContactName,
    emergencyContactNumber: auth.user.profile.emergencyContactNumber,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `${BASE_URL}/authentication/profile/${auth.user.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userInfo),
        }
      );

      Swal.fire("Success", response.message, "success");
      
    } catch (error) {
      Swal.fire("Error", "An error occurred while updating.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg">
      <h2 className="text-2xl font-semibold text-darkgreen mb-4">Personal Information</h2>
      <form className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {[ 
          { label: "First Name", name: "firstName", type: "text" },
          { label: "Last Name", name: "lastName", type: "text" },
          { label: "Email", name: "email", type: "email" },
          { label: "Nationality", name: "nationality", type: "text" },
          { label: "Birthday", name: "birthday", type: "date" },
          { label: "Gender", name: "gender", type: "select", options: ["Male", "Female"] },
          { label: "Emergency Contact Name", name: "emergencyContactName", type: "text" },
          { label: "Emergency Contact Number", name: "emergencyContactNumber", type: "tel" }
        ].map(({ label, name, type, options }) => (
          <div key={name} className="flex flex-col">
            <label className="block text-gray-700">{label}</label>
            {type === "select" ? (
              <select
                name={name}
                value={userInfo[name]}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select</option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={type}
                name={name}
                value={userInfo[name]}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            )}
          </div>
        ))}
      </form>
      <div className="flex justify-end mt-6">
        <button
          onClick={handleUpdate}
          disabled={isLoading}
          className="bg-lightbrown text-white px-6 py-2 rounded-lg hover:bg-opacity-80 transition-all"
        >
          {isLoading ? "Updating..." : "Update Information"}
        </button>
      </div>
    </div>
  );
};

export default PersonalInfo;
