import React, { useState, useEffect } from "react";
import UserProfile from "./UserProfile";
import BASE_URL from "../../config";
import Pagination from "../Pagination";
import { customFetch } from "../../api";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 3;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await customFetch(`${BASE_URL}/authentication/users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Filter users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.UserProfile.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.UserProfile.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  // Get current users to display on the page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const openUserProfile = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-lg">
      <h2 className="text-2xl font-headers font-bold text-darkgreen mb-4">
        Manage Users
      </h2>
      <input
        type="text"
        placeholder="Search by name or email"
        className="w-full p-2 mb-4 border rounded-lg"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {selectedUser ? (
        <UserProfile
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
        />
      ) : (
        <div className="overflow-y-auto max-h-98">
          <ul>
            {currentUsers.map((user) => (
              <li key={user.id} className="border-b py-2">
                <p>
                  <strong>Name:</strong> {user.UserProfile.firstName} {user.UserProfile.lastName}
                </p>
                <p>
                  <strong>Email:</strong> {user.email}
                </p>
                <button
                  onClick={() => openUserProfile(user)}
                  className="bg-lightbrown text-luxwhite font-cta py-2 px-4 rounded-lg mr-4"
                >
                  View Profile
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Show pagination controls */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />

    </div>
  );
};

export default ManageUsers;
