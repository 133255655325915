import React, { useState, useEffect } from "react";
import BASE_URL from "../../config";
import { api } from "../../api";

const HistoryModal = ({ onClose }) => {
  const [historyData, setHistoryData] = useState([]);

  const fetchAppointments = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;

    try {
      const response = await api.get(`${BASE_URL}/appointments/user/${userId}`);
      // Filter appointments with status 'completed' or 'canceled'
      const filteredAppointments = response.data.filter(
        (appointment) => appointment.status === "completed" || appointment.status === "canceled"
      );
      setHistoryData(filteredAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3">
        <h2 className="text-xl font-bold mb-4">Appointment History</h2>
        <table className="w-full border-collapse border-0">
          <thead>
            <tr className="text-left border-b border-gray-200">
              <th className="py-2 px-4">Date</th>
              <th className="py-2 px-4">Type</th>
              <th className="py-2 px-4">Details</th>
              <th className="py-2 px-4">Status</th>
            </tr>
          </thead>
          <tbody>
            {historyData.map((appointment) => (
              <tr key={appointment.appointment_id} className="hover:bg-gray-100">
                <td className="py-2 px-4">
                  {new Date(appointment.appointmentDateTime).toLocaleDateString()}
                </td>
                <td className="py-2 px-4">Appointment</td>
                <td className="py-2 px-4">
                  {appointment.User.username} ({appointment.User.email})
                </td>
                <td className="py-2 px-4">{appointment.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 text-right">
          <button
            onClick={onClose}
            className="font-bold border-b-2 border-green-500 text-gray-500"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoryModal;
