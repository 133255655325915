import React, { useState, useEffect } from "react";
import PersonalInfo from "./PersonalInfo";
import Swal from "sweetalert2";
import BASE_URL from "../../config";
import { jwtDecode } from "jwt-decode";
import { customFetch } from "../../api";

const ProfileModal = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState("profile");
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [sessionExpiration, setSessionExpiration] = useState("Unknown");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Retrieve token and decode expiration
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode the token
        if (decodedToken && decodedToken.exp) {
          // Convert expiration to readable date
          const expirationDate = new Date(decodedToken.exp * 1000).toLocaleString();
          setSessionExpiration(expirationDate);
        }
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, []);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    if (formData.newPassword !== formData.confirmPassword) {
      Swal.fire("Error", "New passwords do not match", "error");
      return;
    }
  
    try {
      const response = await customFetch(`${BASE_URL}/authentication/update-password`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currentPassword: formData.currentPassword,
          newPassword: formData.newPassword,
        }),
      });
  
      if (response.ok) {
        setIsLoading(false);
        Swal.fire("Success", "Password updated successfully", "success");
        setFormData({ currentPassword: "", newPassword: "", confirmPassword: "" });
      } else {
        throw new Error("Failed to update password");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "profile":
        return <PersonalInfo />;
      case "edit":
        return <div>Edit Profile Information</div>;
      case "security":
        return (
          <div>
            <h3 className="text-2xl font-semibold text-darkgreen mb-4">Security Settings</h3>
            {/* Session Expiration Info */}
            <p className="mb-4">
              <strong>Session Expires At:</strong> {sessionExpiration}
            </p>
            
            {/* Update Password Form */}
            <form
              onSubmit={handleUpdatePassword}
              className="space-y-4 border p-4 rounded-lg"
            >
              <div>
                <label className="block text-gray-700 font-bold mb-1">
                  Current Password:
                </label>
                <input
                  type="password"
                  name="currentPassword"
                  value={formData.currentPassword || ""}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-1">
                  New Password:
                </label>
                <input
                  type="password"
                  name="newPassword"
                  value={formData.newPassword || ""}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-bold mb-1">
                  Confirm New Password:
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword || ""}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="flex justify-end mt-6">
                <button
                  onClick={handleUpdatePassword}
                  disabled={isLoading}
                  className="bg-lightbrown text-white px-6 py-2 rounded-lg hover:bg-opacity-80 transition-all"
                >
                  {isLoading ? "Updating..." : "Update Password"}
                </button>
              </div>
            </form>
          </div>
        );
      case "invoices":
        return <div>Invoices</div>;
      default:
        return <div>No content available.</div>;
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center mx-4 sm:mx-8 md:mx-16 lg:mx-24">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl max-h-[85vh] overflow-y-auto mt-[70px] sm:w-11/12 md:w-9/12 lg:w-1/2">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Profile</h2>
          <button onClick={onClose} className="text-red-500 hover:text-red-700">
            Close
          </button>
        </div>

        {/* Tabs */}
        <div className="flex border-b">
          {["profile", "edit", "security", "invoices"].map((tab) => (
            <button
              key={tab}
              className={`flex-1 py-2 text-center ${activeTab === tab ? "font-bold border-b-2 border-green-500" : "text-gray-500"}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {/* Content */}
        <div className="p-4">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default ProfileModal;
