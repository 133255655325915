import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Popup from "../Popup";
import BASE_URL from "../../config";
import { FaDownload, FaEye } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { customFetch } from "../../api";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AnalyticsOverview = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({
    totalBookings: 0,
    totalPayments: 0,
    activeUsers: 0,
    cancelledAppointments: 0,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const chartRef = useRef(null);

  useEffect(() => {
    fetchAnalyticsData();
  }, [startDate, endDate]);

  const fetchAnalyticsData = async () => {
    const query = new URLSearchParams();
    if (startDate) query.append("startDate", startDate.toISOString());
    if (endDate) query.append("endDate", endDate.toISOString());

    await customFetch(`${BASE_URL}/analytics/overview?${query.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response)
      .then((data) => setAnalyticsData(data))
      .catch((error) => console.error("Error fetching analytics data:", error));
  };

  const chartData = {
    labels: ["Bookings", "Payments", "Active Users", "Cancellations"],
    datasets: [
      {
        label: "Bookings",
        data: [analyticsData.totalBookings, 0, 0, 0],
        backgroundColor: "#66bb6a",
      },
      {
        label: "Payments",
        data: [0, analyticsData.totalPayments, 0, 0],
        backgroundColor: "#42a5f5",
      },
      {
        label: "Active Users",
        data: [0, 0, analyticsData.activeUsers, 0],
        backgroundColor: "#AF937B",
      },
      {
        label: "Cancelled Appointments",
        data: [0, 0, 0, analyticsData.cancelledAppointments],
        backgroundColor: "#470A12",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          usePointStyle: false,
          boxWidth: 20,
          padding: 10,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Users Count",
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const downloadChart = () => {
    if (chartRef.current) {
      const base64Image = chartRef.current.toBase64Image();
      const link = document.createElement("a");
      link.href = base64Image;
      link.download = "rma-admin-analytics-chart.png";
      link.click();
    }
  };

  return (
    <div className="p-4 bg-luxwhite rounded-lg shadow-lg">
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-2xl font-headers font-bold text-darkgreen">Analytics Overview</h2>
        <div className="flex space-x-4">
          <button
            onClick={downloadChart}
            title="Download Chart"
            className="text-darkgreen hover:text-green-600"
          >
            <FaDownload size={20} />
          </button>
          <button
            onClick={() => setIsPopupVisible(true)}
            title="View Details"
            className="text-darkgreen hover:text-green-600"
          >
            <FaEye size={20} />
          </button>
        </div>
      </div>

      {/* Date Pickers */}
      <div className="flex justify-between items-center mb-4 space-x-4">
        <div className="flex-1">
          <label className="block text-gray-700 font-bold">Start Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            placeholderText="Select Start Date"
            className="border p-2 rounded w-full"
          />
        </div>
        <div className="flex-1">
          <label className="block text-gray-700 font-bold">End Date</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            placeholderText="Select End Date"
            className="border p-2 rounded w-full"
          />
        </div>
      </div>

      {/* Chat Placeholder */}
      <div className="mb-4 p-4 bg-gray-100 rounded-lg">
        <p className="text-gray-600"></p>
      </div>

      {/* Chart */}
      <div className="relative">
        <Bar ref={chartRef} data={chartData} options={chartOptions}/>
      </div>

      <Popup
        isVisible={isPopupVisible}
        title="Analytics Details"
        message={
          <>
            <p>
              <strong>Total Bookings:</strong> {analyticsData.totalBookings}
            </p>
            <p>
              <strong>Total Payments:</strong> {analyticsData.totalPayments}
            </p>
            <p>
              <strong>Active Users:</strong> {analyticsData.activeUsers}
            </p>
            <p>
              <strong>Cancelled Appointments:</strong>{" "}
              {analyticsData.cancelledAppointments}
            </p>
          </>
        }
        onClose={() => setIsPopupVisible(false)}
      />
    </div>
  );
};

export default AnalyticsOverview;
