import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BASE_URL from "../../config";
import Pagination from "../Pagination";
import { customFetch } from "../../api";

const Notifications = () => {
  const [activeTab, setActiveTab] = useState("inbox");
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(3); // Show 3 messages per page
  const [totalPages, setTotalPages] = useState(1);

  const currentUserId = JSON.parse(localStorage.getItem("user"))?.id;

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        // Fetch notifications from the API
        const response = await customFetch(`${BASE_URL}/notifications`, {});

        const apiMessages = response.map((notification) => ({
          id: notification.notifications_id,
          subject: notification.message.substring(0, 20) + "...",
          body: notification.message,
          sentAt: "New",
        }));

        // Fake message for testing
        const fakeMessages = [
          {
            id: 2,
            subject: "New Features Released",
            body: "We have added new features to improve your experience. Check them out!",
            sentAt: "2025-01-05 3:30 PM",
          },
        ];

        // Combine API messages with fake messages
        const allMessages = [...apiMessages, ...fakeMessages];
        setMessages(allMessages);

        // Set total pages for pagination
        setTotalPages(Math.ceil(allMessages.length / messagesPerPage));
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [currentUserId]);

  // Get current messages for the current page
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg">

      <h2 className="text-2xl font-headers font-semibold text-darkgreen mb-4">Notifications</h2>

      {activeTab === "inbox" && (
        <div>
          <div className="max-h-96 overflow-y-auto border border-gray-300 rounded-lg">
            {currentMessages.map((msg) => (
              <div
                key={msg.id}
                className="p-4 border-b cursor-pointer hover:bg-gray-100"
                onClick={() => setSelectedMessage(msg)}
              >
                <h3 className="font-semibold text-gray-800">{msg.subject}</h3>
                <p className="text-sm text-gray-600">{msg.sentAt}</p>
              </div>
            ))}
          </div>

          {/* Message Details Modal */}
          {selectedMessage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
              onClick={() => setSelectedMessage(null)}
            >
              <div
                className="bg-white p-6 rounded-lg shadow-lg w-96 relative"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={() => setSelectedMessage(null)}
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full h-8 w-8 flex items-center justify-center hover:bg-red-600"
                >
                  ✕
                </button>
                <h3 className="text-lg font-bold text-gray-800">{selectedMessage.subject}</h3>
                <p className="text-sm text-gray-600 mb-4">{selectedMessage.sentAt}</p>
                <p className="text-gray-700 mb-6">{selectedMessage.body}</p>
                <button
                  onClick={() => setSelectedMessage(null)}
                  className="font-bold border-b-2 border-green-500 text-gray-500"
                >
                  Close
                </button>
              </div>
            </div>
          )}

          {/* Pagination */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Notifications;
