import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import BASE_URL from "../../config";
import { toast } from 'react-toastify';
import Modal from "./Modal";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { customFetch } from "../../api";

const ManageSchedule = () => {
  const [doctors, setDoctors] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [daySlots, setDaySlots] = useState([]);
  const [loadingDoctors, setLoadingDoctors] = useState(true);
  const [loadingSchedules, setLoadingSchedules] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newTimeSlot, setNewTimeSlot] = useState({
    start_time: "",
    end_time: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const hours = Array.from({ length: 9 }, (_, i) => i + 8);
  const [note, setNote] = useState("");


  // Fetch doctors
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await customFetch(`${BASE_URL}/admin/doctors`);
        setDoctors(response);
      } catch (error) {
        setErrorMessage("Error fetching doctors: " + error.message);
      } finally {
        setLoadingDoctors(false);
      }
    };
    fetchDoctors();
  }, []);

  // Fetch schedules when a doctor is selected
  const fetchSchedules = async () => {
    if (selectedDoctor) {
      setLoadingSchedules(true);
      try {
        const response = await customFetch(
          `${BASE_URL}/admin/doctor/${selectedDoctor.id}/schedules`
        );
        setAvailableSlots(response);
        
      } catch (error) {
        setErrorMessage("Error fetching schedules: " + error.message);
      } finally {
        setLoadingSchedules(false);
      }
    }
  };



  const handleDateClick = (date) => {
    // Filter schedules to get slots for the selected date
    const selectedSchedule = availableSlots.find(
      (schedule) =>
        new Date(schedule.date).toLocaleDateString() ===
        date.toLocaleDateString()
    );

    // If a schedule exists, get the `TimeSlots` array; otherwise, set an empty array
    const daySlots = selectedSchedule ? selectedSchedule.TimeSlots : [];

    // Update states
    setDaySlots(daySlots);
    setSelectedDate(date);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const dateSlots = mergeTimeSlots(
      daySlots.filter((slot) => {
        const availableSlot = availableSlots.find(slot => {
          const availableDate = new Date(slot.date);
          return availableDate.toDateString() === selectedDate.toDateString();
        });
        return availableSlot ? true : false;
      })
    );
    setDaySlots(dateSlots);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  
  
  


  useEffect(() => {
    fetchSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoctor]);
 


  const handleAddTimeSlot = async (hour) => {
  const startTime = new Date(selectedDate);
  startTime.setHours(hour, 0, 0, 0);

  const endTime = new Date(selectedDate);
  endTime.setHours(hour + 1, 0, 0, 0);

  const startDateOnly = startTime.toISOString().split("T")[0];
  const scheduleData = {
    doctor_id: selectedDoctor.id,
    date: startDateOnly,
    is_available: true,
    note: note || '',
    TimeSlots: [
      { start_time: startTime, end_time: endTime },
    ],
  };

  try {
   await customFetch(
      `${BASE_URL}/admin/schedule/${selectedDoctor.id}/time-slot`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(scheduleData),
      }
    );

    toast.success("Time slot successfully added!");
    setNewTimeSlot({ start_time: "", end_time: "" });
    setNote("");
    

    closeModal() //force close for hard refresh 

    await fetchSchedules();
  } catch (error) {
    setErrorMessage("Error adding time slot: " + error.message);
  }
};

const handleDeleteTimeSlot = async (slotId) => {
  try {
    await customFetch(`${BASE_URL}/admin/time-slot/${slotId}`, {
      method: "DELETE",
    });
    await fetchSchedules();
    setDaySlots((prevSlots) =>
      prevSlots.filter((slot) => slot.id !== slotId)
    );
  } catch (error) {
    setErrorMessage("Error deleting time slot: " + error.message);
  }
};


  // Helper: Merge overlapping or contiguous time slots
  const mergeTimeSlots = (slots) => {
    if (!slots || slots.length === 0) return [];
    const sortedSlots = slots.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

    const merged = [];
    let currentSlot = { ...sortedSlots[0] };

    for (let i = 1; i < sortedSlots.length; i++) {
      const nextSlot = sortedSlots[i];
      const currentEnd = new Date(currentSlot.end_time);
      const nextStart = new Date(nextSlot.start_time);

      if (currentEnd >= nextStart) {
        // Merge slots
        currentSlot.end_time = new Date(
          Math.max(new Date(currentSlot.end_time), new Date(nextSlot.end_time))
        ).toISOString();
      } else {
        merged.push(currentSlot);
        currentSlot = { ...nextSlot };
      }
    }
    merged.push(currentSlot);
    return merged;
  };

  function findMatchingSlot(hour, daySlots) {
    return daySlots.find((s) => {
      if (!s) {
        console.error('Encountered undefined slot:', s);
        return false;
      }
  
      const startTime = s.start_time.slice(0, 5); // "HH:mm"
      const endTime = s.end_time.slice(0, 5); // "HH:mm"
      const currentHour = `${hour < 10 ? '0' : ''}${hour}:00`;
  
      const currentHourDate = new Date(`1970-01-01T${currentHour}:00`);
      const startTimeDate = new Date(`1970-01-01T${startTime}:00`);
      const endTimeDate = new Date(`1970-01-01T${endTime}:00`);
  
      const isMatch = currentHourDate >= startTimeDate && currentHourDate < endTimeDate;
  
      return isMatch;
    });
  }
  


  return (
    <div className="bg-luxwhite p-4 rounded-lg shadow-lg">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      
      <h2 className="text-2xl font-headers font-bold text-darkgreen mb-4">
        Manage Schedule
      </h2>

      {/* Doctor selection */}
      {loadingDoctors ? (
        <p>Loading doctors...</p>
      ) : errorMessage ? (
        <p className="text-red-500">{errorMessage}</p>
      ) : doctors.length === 0 ? (
        <p className="text-red-500">No doctors available.</p>
      ) : (
        <div className="mb-4">
          <label htmlFor="doctorSelect" className="block mb-2 font-paragraph">
            Select Doctor
          </label>
          <select
            id="doctorSelect"
            value={selectedDoctor?.id || ""}
            onChange={(e) => {
              const doctorId = e.target.value;
              setSelectedDoctor(
                doctors.find((doctor) => doctor.id === parseInt(doctorId))
              );
            }}
            className="p-2 border rounded w-full"
          >
            <option value="">Click on Doctor</option>
            {doctors.map((doctor) => (
              <option key={doctor.id} value={doctor.id}>
                {doctor.username}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* End Doctor selection */}

      
      {/* Main Calendar */}
      <div>
        <Calendar
          onChange={setSelectedDate}
          value={selectedDate}
          className="rounded-lg"
          tileDisabled={({ date }) => date < new Date()}
          tileClassName={({ date }) => {
            const dateString = date.toLocaleDateString();
            const isAvailable = availableSlots.some(
              (slot) => new Date(slot.date).toLocaleDateString() === dateString
            );
            return isAvailable ? "available-slot" : "";
          }}
          tileContent={({ date }) => {
            const dateString = date.toLocaleDateString();
            const schedules = availableSlots.filter(
              (slot) => new Date(slot.date).toLocaleDateString() === dateString
            );
            return schedules.length > 0 ? (
              <div className="slot-lines">
                {schedules.map((slot, index) => (
                  <div key={index}>
                    {slot.TimeSlots && slot.TimeSlots.length > 0 && (
                      slot.TimeSlots.map((_, lineIndex) => (
                        <div
                          key={lineIndex}
                          className="slot-line bg-gray-400"
                          style={{ height: '3px', marginBottom: '2px' }}
                        ></div>
                      ))
                    )}
                  </div>
                ))}
              </div>
            ) : null;
          }}
          onClickDay={handleDateClick}
        />

        {/* Modal for Day View */}
        {isModalVisible && (
          <Modal onClose={closeModal}>
          <div>
            <h3 className="text-lg font-semibold">
              Manage Slots for {selectedDate.toDateString()}
            </h3>
        
            <div className="day-view-grid grid grid-cols-4 gap-4 mt-4">
              {hours.map((hour) => {
                const slot = findMatchingSlot(hour, daySlots);
                
        
                return (
                  <div
                    key={hour}
                    className={`hour-slot p-2 border rounded relative ${
                      slot ? "bg-green-100" : "bg-gray-100"
                    }`}
                  >
                    <div className="hour-label text-sm font-semibold block">
                      {hour}:00
                    </div>
                    {slot ? (
                      <>
                        <div className="slot-details mt-1 text-xs block">
                        <p>
                          {`Slot: ${new Date(`1970-01-01T${slot.start_time}`).getHours()} - ${new Date(`1970-01-01T${slot.end_time}`).getHours()}`}
                        </p>

                        </div>
                        <div className="flex bottom-2 right-2 text-xs">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteTimeSlot(slot.id);
                            }}
                            className="text-red-500"
                          >
                            Delete
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="flex bottom-2 right-2 text-xs">
                        <button
                          onClick={() => handleAddTimeSlot(hour)}
                          className="text-gray-500"
                        >
                          Add slot
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
        
        )}
      </div>
      {/* End Main Calendar */}


    </div>
  );
};

export default ManageSchedule;
