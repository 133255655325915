import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { FaEdit } from "react-icons/fa";
import BASE_URL from "../config";
import Pagination from "./Pagination";

Modal.setAppElement("#root");

const MedicalRecordsModal = ({ selectedPatient, isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("medical");
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [editableFields, setEditableFields] = useState({
    diagnosis: "",
    treatment: "",
    prescription: "",
    notes: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 3;
  const [modalHeight, setModalHeight] = useState("auto");

  const handleEditChange = (field, value) => {
    setEditableFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      const payload = {
        ...editableFields,
        patient_id: selectedPatient.id,
        created_by: user?.id || null,
        record_id: selectedRecord?.record_id || undefined,
      };

      const response = await fetch(`${BASE_URL}/medical-records/records`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: selectedRecord?.record_id
            ? "Medical record updated successfully."
            : "Medical record created successfully.",
          confirmButtonColor: "#4CAF50",
        });

        setSelectedRecord(null);
        setActiveTab("medical");
        setLoading(false);
        onClose();
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      setError("Failed to save medical record. Please try again.");
      setLoading(false);
    }
  };

  const paginatedRecords = selectedPatient?.medicalRecords.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const totalPages = Math.ceil(
    (selectedPatient?.medicalRecords.length || 0) / recordsPerPage
  );

  useEffect(() => {
    if (isOpen) {
      const recordsHeight = Math.min(
        selectedPatient?.medicalRecords.length || 0,
        recordsPerPage
      ) * 70;
      const extraHeight = activeTab === "edit" ? 470 : 250;
      setModalHeight(`${recordsHeight + extraHeight}px`);
    }
  }, [selectedPatient, isOpen, currentPage, activeTab]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Medical Records Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%", // Make modal width responsive
          maxWidth: "600px", // Set max width for larger screens
          height: modalHeight,
          padding: "20px", // Reduced padding for smaller screens
          borderRadius: "10px",
          overflow: "visible",
        },
      }}
    >
      {/* Tabs */}
      <div className="flex border-b">
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === "medical" ? "font-bold border-b-2 border-green-500" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("medical")}
        >
          Medical Records
        </button>
        <button
          className={`flex-1 py-2 text-center ${
            activeTab === "edit" ? "font-bold border-b-2 border-green-500" : "text-gray-500"
          }`}
          onClick={() => setActiveTab("edit")}
        >
          Edit
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "medical" && (
        <div className="mt-4">
          {selectedPatient?.medicalRecords.length === 0 ? (
            <>
              <p>No medical records available.</p>
              <button
                onClick={() => {
                  setSelectedRecord(selectedPatient);
                  setEditableFields({
                    diagnosis: "",
                    treatment: "",
                    prescription: "",
                    notes: "",
                  });
                  setActiveTab("edit");
                }}
                className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg"
              >
                Add Medical Record
              </button>
            </>
          ) : (
            <>
              <ul>
                {paginatedRecords?.map((record) => (
                  <li key={record.record_id} className="border-b py-2">
                    <p>
                      <strong>Diagnosis:</strong> {record.diagnosis}
                    </p>
                    <button
                      onClick={() => {
                        setSelectedRecord(record);
                        setEditableFields({
                          diagnosis: record.diagnosis || "",
                          treatment: record.treatment || "",
                          prescription: record.prescription || "",
                          notes: record.notes || "",
                        });
                        setActiveTab("edit");
                      }}
                      className="text-blue-500 hover:underline flex items-center gap-1"
                    >
                      <FaEdit /> Edit
                    </button>
                  </li>
                ))}
              </ul>

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </div>
      )}

      {activeTab === "edit" && selectedRecord && (
        <div className="mt-4">
          <label>Diagnosis</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg mb-4"
            value={editableFields.diagnosis}
            onChange={(e) => handleEditChange("diagnosis", e.target.value)}
          />
          <label>Treatment</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg mb-4"
            value={editableFields.treatment}
            onChange={(e) => handleEditChange("treatment", e.target.value)}
          />

          <label>Prescription</label>
          <input
            type="text"
            className="w-full p-2 border rounded-lg mb-4"
            value={editableFields.prescription}
            onChange={(e) => handleEditChange("prescription", e.target.value)}
          />

          <label>Notes</label>
          <textarea
            className="w-full p-2 border rounded-lg mb-4"
            value={editableFields.notes}
            onChange={(e) => handleEditChange("notes", e.target.value)}
          ></textarea>
          <button
            onClick={handleSave}
            className={`bg-green-500 text-white py-2 px-4 rounded-lg ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            Save
          </button>
        </div>
      )}

      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}

      <div className="flex justify-end mt-4">
        <button
          onClick={onClose}
          className="text-gray-500 hover:underline font-bold"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default MedicalRecordsModal;
