import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import AdminDashboard from "./pages/AdminDashboard";
import DoctorDashboard from "./pages/DoctorDashboard";
import VirtualClinic from "./pages/VirtualClinic";
import MoreInformation from "./pages/MoreInformation.jsx";
import ContactUs from "./pages/ContactUs";
import PrivateRoute from "./components/PrivateRoute.jsx";
import AdminRoute from "./components/AdminRoute.jsx";
import DoctorRoute from "./components/DoctorRoute.jsx";
import { AuthProvider } from "./context/AuthContext.jsx";
import ViewTreatments from "./pages/ViewTreatments";
import BackgroundWrapper from "./components/Style/BackgroundWrapper.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import MainClinic from "./pages/MainClinic.jsx";
import Booking from "./pages/Booking.jsx";
import PaymentOptions from "./pages/PaymentOptions.jsx";
import HomePage from "./pages/HomePage.jsx";
import Fallback from "./pages/Fallback";
import TermsAndConditions from "./pages/TermsAndConditions.jsx";
import BASE_URL from "./config.js";

const App = () => {
  const [maintenanceMode, setMaintenanceMode] = useState({ full: false, half: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMaintenanceMode = async () => {
      try {
        const response = await fetch(`${BASE_URL}/maintenance`);
        const data = await response.json();
        setMaintenanceMode({ full: data.full_maintenance, half: data.half_maintenance });
      } catch (error) {
        console.error("Error fetching maintenance mode:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMaintenanceMode();
  }, []);

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen bg-gradient-to-r from-[#1B2E22] to-[#535f57]">
        <div className="relative">
          {/* Outer Circle */}
          <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500 border-opacity-50"></div>
  
          {/* Inner Circle */}
          <div className="absolute inset-4 rounded-full h-24 w-24 bg-white"></div>
  
          {/* Heart Icon */}
          <div className="absolute inset-8 flex items-center justify-center">
            <svg
              className="animate-pulse w-8 h-8 text-pink-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"></path>
            </svg>
          </div>
        </div>
      </div>
    );
  

  if (maintenanceMode.full) {
    // Full maintenance: Only login and admin routes should be open
    return (
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin-dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />
            <Route path="*" element={<Fallback />} />
          </Routes>
        </AuthProvider>
      </Router>
    );
  }

  if (maintenanceMode.half) {
    // Half maintenance: Open login, admin, and doctor routes
    return (
      <Router>
        <AuthProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Fallback />} />

            {/* Admin Routes */}
            <Route path="/admin-dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />

            {/* Doctor Routes */}
            <Route path="/doctor-dashboard" element={<DoctorRoute><DoctorDashboard /></DoctorRoute>} />
          </Routes>
        </AuthProvider>
      </Router>
    );
  }

  // If neither full nor half maintenance, open all routes
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/view-treatments" element={<ViewTreatments />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/payment-options" element={<PaymentOptions />} />
          <Route path="*" element={<Fallback />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/aesthetics-clinic" element={<BackgroundWrapper><MainClinic /></BackgroundWrapper>} />
          <Route path="/virtual-clinic" element={<BackgroundWrapper><VirtualClinic /></BackgroundWrapper>} />
          <Route path="/more-information" element={<MoreInformation />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/forgot-password" element={<BackgroundWrapper><ForgotPassword /></BackgroundWrapper>} />

          {/* Protected Routes */}
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

          {/* Admin Routes */}
          <Route path="/admin-dashboard" element={<AdminRoute><AdminDashboard /></AdminRoute>} />

          {/* Doctor Routes */}
          <Route path="/doctor-dashboard" element={<DoctorRoute><DoctorDashboard /></DoctorRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
