import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Includes/Header";
import Footer from "../components/Includes/Footer";
import AppointmentManagement from "../components/DoctorDashboard/AppointmentManagement";
import PatientInfo from "../components/DoctorDashboard/PatientInfo";
import ScheduleManagement from "../components/DoctorDashboard/ScheduleManagement";
import Notifications from "../components/DoctorDashboard/Notifications";
import DoctorAnalytics from "../components/DoctorDashboard/DoctorAnalytics";
import { AuthContext } from "../context/AuthContext";

const DoctorDashboard = () => {
  const { auth } = useContext(AuthContext);
  const userName = auth.user ? auth.user.username : "User";
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showWelcome, setShowWelcome] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const components = [
    <AppointmentManagement key="appointments" />,
    <PatientInfo key="patient" />,
    <ScheduleManagement key="schedule" />,
    <Notifications key="notifications" />,
    <DoctorAnalytics key="doctor" />
  ];

  const itemsPerPage = 3;
  const totalPages = Math.ceil(components.length / itemsPerPage);

  // Get paginated components
  const getPaginatedComponents = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return components.slice(startIndex, startIndex + itemsPerPage);
  };

  const [selectedMenu, setSelectedMenu] = useState(null); 

  const menuItems = [
    { id: 88, name: "Dashboard" },
    { id: 1, name: "Appoint..." },
    { id: 2, name: "Patient" },
    { id: 3, name: "Schedule" },
    { id: 4, name: "Notif" },
    { id: 5, name: "Doctor" },
    { id: 6, name: "Analytics" },
  ];

  const itemsToPageMapping = {
    1: [1, 2, 3],  // Page 1 has items 1, 2, 3
    2: [4, 5, 6],  // Page 2 has items 4, 5, 6
  };

  const handleMenuClick = (id) => {
    setSelectedMenu(id);
    let page = 1;
    for (const [pageNumber, items] of Object.entries(itemsToPageMapping)) {
      if (items.includes(id)) {
        page = parseInt(pageNumber);
        break;
      }
    }
    setCurrentPage(page);
  };

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close sidebar on mobile view when the page loads or on resize
  useEffect(() => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }else {
      const timer = setTimeout(() => setShowWelcome(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [isMobile]);

  return (
    <div className="bg-almond">
       {/* White rectangle with menu options */}
       <div
          className={`absolute top-0 left-0 w-[120px] min-h-screen bg-white z-10 p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}
        >
          <ul className="space-y-4">
            {menuItems.map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => handleMenuClick(item.id)}
                  className={`w-full text-left py-2 px-4 font-semibold ${selectedMenu === item.id ? 'text-green-500 border-b-2 border-green-500' : 'text-gray-700'}`}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      <Header />

      <main className="container mx-auto px-4 p-8 md:pt-[100px] pt-[60px] min-h-screen bg-almond">
        {showWelcome && (
          <section className="bg-brown-200 rounded-lg shadow-lg p-6 md:p-8 mb-6 lg:mb-8 text-center lg:text-left">
            <h1 className=" md:text-3xl lg:text-h2 text-[25px] font-bold font-headers text-darkgreen">
               Welcome, Dr. {userName}
            </h1>
            <p className="mt-2 md:mt-4 text-base md:text-lg text-p text-gray-700">
              Manage your schedule, access patient information, and communicate
              with patients efficiently. Review upcoming appointments, update
              records, and manage your availability.
            </p>
          </section>
        )}

        {/* Paginated Components */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8" style={{ height: "calc(100vh - 170px)", overflowY: "auto" }}>
          {getPaginatedComponents()}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-end mt-6">
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className={`px-3 py-1 rounded-lg shadow-md ${
                currentPage === 1 ? "bg-gray-300 text-gray-600" : "bg-green-500 text-white hover:bg-green-600"
              }`}
            >
              Previous
            </button>
            <span className="text-sm font-medium">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className={`px-3 py-1 rounded-lg shadow-md ${
                currentPage === totalPages ? "bg-gray-300 text-gray-600" : "bg-green-500 text-white hover:bg-green-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default DoctorDashboard;
