import React, { useEffect, useState } from "react";
import BASE_URL from "../../config";
import RescheduleModal from "../RescheduleModal";
import { FaCalendarAlt } from "react-icons/fa";
import Pagination from "../Pagination";
import { customFetch } from "../../api";

const UpcomingAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const fetchAppointments = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user?.id;
    try {
      const response = await customFetch(`${BASE_URL}/appointments/user/${userId}`);
      
      // Filter for upcoming appointments with status 'scheduled'
      const upcomingAppointments = response.filter((appointment) => {
        const appointmentDate = new Date(appointment.appointmentDateTime);
        return appointment.status === "scheduled";
      });
      
      setAppointments(upcomingAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  // Fetch appointments from backend
  useEffect(() => {
    fetchAppointments();
  }, []);

  // Calculate pagination indexes
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = appointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOpenModal = (appointment) => {
    setSelectedAppointment(appointment);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAppointment(null);
    fetchAppointments();
  };

  return (
    <div className="bg-white p-4 shadow-lg rounded-lg">
      <h2 className="text-2xl font-headers font-semibold text-darkgreen mb-4">
        Upcoming Appointments
      </h2>
      <ul className="divide-y divide-gray-300">
        {currentAppointments.length === 0 ? (
          <li className="py-4 text-gray-500">No upcoming appointments.</li>
        ) : (
          currentAppointments.map((appointment, index) => (
            <li key={appointment.appointment_id} className="py-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center w-full">
                <div>
                  <span className="text-gray-700 text-lg">
                    <span className="inline-block bg-lightbrown text-white py-1 px-3 rounded">
                      {new Date(appointment.appointmentDateTime).toLocaleString()}
                    </span>
                  </span>
                </div>
                {index === 0 && currentPage === 1 && (
                  <span className="ml-2 text-xs font-semibold text-lightbrown">
                    Next Appointment
                  </span>
                )}
              </div>
              <button
                onClick={() => handleOpenModal(appointment)}
                className="text-blue-500 text-sm flex items-center w-full sm:w-auto mt-2 sm:mt-0 sm:ml-4"
              >
                <FaCalendarAlt className="mr-2" /> Reschedule
              </button>
            </li>
          ))
        )}
      </ul>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(appointments.length / appointmentsPerPage)}
        onPageChange={handlePageChange}
      />

      {/* Reschedule Modal */}
      {selectedAppointment && (
        <RescheduleModal
          appointmentId={selectedAppointment.appointment_id}
          doctorId={selectedAppointment.doctorId}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default UpcomingAppointments;
