import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import BASE_URL from "../../config";
import getTrimmedTokenAndExpiry from '../../utils';
import Switch from "react-switch";
import { customFetch } from "../../api";


const UserProfile = ({ user, onClose }) => {
  const [formData, setFormData] = useState({
    firstName: user.UserProfile.firstName || "",
    lastName: user.UserProfile.lastName || "",
    email: user.email || "",
    nationality: user.UserProfile.nationality || "",
    birthday: user.UserProfile.birthday || "",
    gender: user.UserProfile.gender || "",
    emergencyContactName: user.UserProfile.emergencyContactName || "",
    emergencyContactNumber: user.UserProfile.emergencyContactNumber || "",
    roleId: user.Role.name === "admin" ? 1 : user.Role.name === "doctor" ? 3 : 2, // Default to 'Patient'
  });

  const [activeTab, setActiveTab] = useState("basic");
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [accountCreationDate, setAccountCreationDate] = useState("");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdate = async () => {
    try {
      await customFetch(
        `${BASE_URL}/authentication/profile/${user.id}`,
        {
          method: "PUT",
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            firstName: formData.firstName,
            lastName: formData.lastName,
            nationality: formData.nationality,
            birthday: formData.birthday,
            gender: formData.gender,
            emergencyContactName: formData.emergencyContactName,
            emergencyContactNumber: formData.emergencyContactNumber,
          }),
        }
      );

      await customFetch(
        `${BASE_URL}/authentication/role/${user.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ roleId: formData.roleId }),
        }
      );

        Swal.fire("Updated!", "User profile updated successfully", "success");
        onClose();
    } catch (error) {
      console.error("Error updating user:", error);
      Swal.fire("Error", "Failed to update user profile", "error");
    }
  };

  // Fetch medical records when the "Medical Records" tab is clicked
  const fetchMedicalRecords = async () => {
    setLoadingRecords(true);
    try {
      const data = await customFetch(`${BASE_URL}/medical-records/${user.id}`, {});

      setMedicalRecords(data);

    } catch (error) {
      console.error("Error fetching medical records:", error);
      Swal.fire("Error", "Failed to load medical records", "error");
    } finally {
      setLoadingRecords(false);
    }
  };

  const fetchAppointments = async () => {
    try {
      const data = await customFetch(`${BASE_URL}/appointments/user/${user.id}`, {
        method: "GET",
      });

      setAppointments(data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const [isActive, setIsActive] = useState(user.active);

  const toggleStatus = async (newStatus) => {
    try {
      await customFetch(`${BASE_URL}/authentication/user/${user.id}/inactive`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ active: newStatus }),
      });

      setIsActive(newStatus);

      Swal.fire(
        "Success",
        `User has been ${newStatus ? "activated" : "deactivated"}`,
        "success"
      );
    } catch (error) {
      console.error("Error updating user status:", error);
      Swal.fire("Error", "Failed to update user status", "error");
    }
  };

  const handleUpdatePassword = async () => {
    try {
      await customFetch(`${BASE_URL}/authentication/user/${user.id}/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword }),
      });
  
      Swal.fire("Success", "Password updated successfully", "success");
    } catch (error) {
      console.error("Error updating password:", error);
      Swal.fire("Error", "Failed to update password", "error");
    }
  };

  const fetchSecurityInfo = async () => {
    try {
      const data = await customFetch(`${BASE_URL}/authentication/user/${user.id}/security`, {
        method: "GET",
      });

      setRefreshToken(data.refreshToken);
      setAccountCreationDate(new Date(data.createdAt).toLocaleString());
    } catch (error) {
      console.error("Error fetching security info:", error);
    }
  };
  
  const { trimmedToken, expirationDate } = getTrimmedTokenAndExpiry(refreshToken);

  
  
  

  useEffect(() => {
    if (activeTab === "medical") {
      fetchMedicalRecords();
    }
    if (activeTab === "appointments") {
      fetchAppointments();
    }
    if (activeTab === "security") {
      fetchSecurityInfo();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg ">
        <h2 className="text-2xl font-bold text-darkgreen mb-4">
          User Profile - {formData.firstName} {formData.lastName}
        </h2>

        {/* Tabs */}
        <div className="flex border-b">
          <button
            className={`flex-1 py-2 text-center ${activeTab === "basic" ? "font-bold border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("basic")}
          >
            Basic Info
          </button>
          <button
            className={`flex-1 py-2 text-center ${activeTab === "medical" ? "font-bold border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("medical")}
          >
            Medical Records
          </button>
          <button
            className={`flex-1 py-2 text-center ${activeTab === "appointments" ? "font-bold border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("appointments")}
          >
            Appointments
          </button>
          <button
            className={`flex-1 py-2 text-center ${activeTab === "security" ? "font-bold border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("security")}
          >
            Security
          </button>
          <button
            className={`flex-1 py-2 text-center ${activeTab === "profileDetails" ? "font-bold border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("profileDetails")}
          >
            Profile Details
          </button>
        </div>

        {/* Tab Content */}
        {activeTab === "basic" && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-bold">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                readOnly
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold">Nationality:</label>
              <input
                type="text"
                name="nationality"
                value={formData.nationality}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold">Birthday:</label>
              <input
                type="date"
                name="birthday"
                value={formData.birthday}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold">Gender:</label>
              <input
                type="text"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-bold">Role:</label>
              <select
                name="roleId"
                value={formData.roleId}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              >
                <option value="1">Admin</option>
                <option value="2">Patient</option>
                <option value="3">Doctor</option>
                
              </select>
            </div>
          </div>
        )}

        {activeTab === "profileDetails" && (
          <div className="mt-4">
            <h3 className="font-semibold text-lg">Passport/ID</h3>
            <p><strong>Type:</strong> {user.UserProfile.meta.passportOrId?.type || "Not Provided"}</p>
            <p><strong>Number:</strong> {user.UserProfile.meta.passportOrId?.number || "Not Provided"}</p>

            <h3 className="font-semibold text-lg mt-4">Medical Aid</h3>
            <p><strong>Name:</strong> {user.UserProfile.meta.medicalAid?.name || "Not Provided"}</p>
            <p><strong>Scheme:</strong> {user.UserProfile.meta.medicalAid?.scheme || "Not Provided"}</p>
            <p><strong>Number:</strong> {user.UserProfile.meta.medicalAid?.number || "Not Provided"}</p>
          </div>
        )}

        {activeTab === "medical" && (
          <div className="mt-4">
            {loadingRecords ? (
              <p>Loading medical records...</p>
            ) : (
              <div>
                {medicalRecords.length > 0 ? (
                  <ul>
                    {medicalRecords.map((record) => (
                      <li key={record.record_id}>
                        <strong>Diagnosis:</strong> {record.diagnosis} <br />
                        <strong>Treatment:</strong> {record.treatment} <br />
                        <strong>Prescription:</strong> {record.prescription} <br />
                        <strong>Date:</strong> {new Date(record.record_date).toLocaleDateString()} <br />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No medical records found.</p>
                )}
              </div>
            )}
          </div>
        )}

        {activeTab === "appointments" && (
          <div className="mt-4">
            {appointments.length > 0 ? (
              <div className="space-y-4">
                {appointments.map((appointment) => (
                  <div
                    key={appointment.appointment_id}
                    className="p-4 border rounded shadow-lg bg-white"
                  >
                    <h3 className="text-xl font-semibold">
                      Appointment with Doctor {appointment.User.username}
                    </h3>
                    <p><strong>Date:</strong> {new Date(appointment.appointmentDateTime).toLocaleString()}</p>
                    <p><strong>Status:</strong> {appointment.status}</p>
                    {appointment.notes && <p><strong>Notes:</strong> {appointment.notes}</p>}
                  </div>
                ))}
              </div>
            ) : (
              <p>No appointments found</p>
            )}
          </div>
        )}


      {activeTab === "security" && (
        <div className="mt-4">
          <h3 className="text-xl font-semibold">Security Settings</h3>
          
          {/* Account Information */}
          <div className="my-4">
            <p><strong>Account Created On:</strong> {accountCreationDate}</p>
            <p><strong>Active Refresh Token:</strong> {trimmedToken}</p>
            {expirationDate && (
              <p><strong>Expires On:</strong> {expirationDate.toLocaleString()}</p>
            )}
            <p className="mt-[4px]"><strong>Is Account Active:</strong> <Switch
              onChange={toggleStatus} 
              checked={isActive}
              offColor="#767577"
              onColor="#81b0ff"
              offHandleColor="#f4f3f4"
              onHandleColor="#58d68d"
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={40}
              className="react-switch"
            /></p>
          </div>

          

          {/* Update Password */}
          <div className="mt-4">
            <label htmlFor="newPassword" className="block text-gray-700">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="border border-gray-300 px-3 py-2 w-full rounded"
            />
            <button
              onClick={handleUpdatePassword}
              className="bg-green-500 text-white px-4 py-2 mt-2 rounded"
            >
              Update Password
            </button>
          </div>
        </div>
      )}


        {/* Modal Actions */}
        {activeTab === "basic" && (
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Update
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
