// src/utils/api.js

import axios from 'axios';

// Utility function for signOut - you can place this in the same file or import it from another file
const signOut = () => {
  localStorage.clear();
  window.location.href = '/login';  // Redirect to login page
};

// Create a custom Axios instance with response interceptor
export const api = axios.create({
    baseURL: process.env.BASE_URL,  // Replace with your actual base URL
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  // Add a request interceptor to dynamically set the Authorization header
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token'); // Get the token dynamically
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      // Handle request errors
      return Promise.reject(error);
    }
  );
  
  // Add a response interceptor to handle 403 errors globally
  api.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response && error.response.status === 403) {
        console.log('Forbidden response, signing out...');
        signOut(); // Implement your signOut function
      }
      return Promise.reject(error); // Propagate other errors
    }
  );

// Custom fetch wrapper for global error handling
export const customFetch = async (url, options = {}) => {
  const token = localStorage.getItem('token');
  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }

  try {
    const response = await fetch(url, options);

    if (response.status === 403) {
      console.log('Forbidden response, signing out...');
      signOut();  // Automatically sign out on forbidden response
    }

    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error with request:', err);
  }
};
