import React, { useState, useEffect } from "react";
import BASE_URL from "../../config";
import { FaCheck, FaRedo } from "react-icons/fa";
import RescheduleModal from "../RescheduleModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { api } from "../../api";

const AppointmentManagement = () => {
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleOpenRescheduleModal = (appointmentId, doctorId) => {
    setSelectedAppointment({ appointmentId, doctorId });
    setShowRescheduleModal(true);
  };

  const handleCloseRescheduleModal = () => {
    setShowRescheduleModal(false);
    setSelectedAppointment(null);
  };

  const itemsPerPage = 3;

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user || !user.id) {
        console.error("User ID not found in local storage");
        return;
      }

      try {
        const response = await api.get(
          `${BASE_URL}/appointments/doctor-appointments`,
          {
            params: { id: user.id },
          }
        );
        setAppointments(response.data);
        setFilteredAppointments(response.data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    if(!showRescheduleModal)
      fetchAppointments();
  }, [showRescheduleModal]);

  const handleComplete = async (id) => {
    try {
      await api.patch(`${BASE_URL}/appointments/${id}/complete`);
      setAppointments((prev) =>
        prev.map((appt) =>
          appt.appointment_id === id ? { ...appt, status: "completed" } : appt
        )
      );
      toast.success("Appointment status updated successfully!");
    } catch (error) {
      console.error("Error completing appointment:", error);
    }
  };


  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = appointments.filter(
      (appt) =>
        appt.patientEmail.toLowerCase().includes(query.toLowerCase()) ||
        appt.patientName?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredAppointments(filtered);
    setCurrentPage(1);
  };

  const handleDateFilter = (date) => {
    setFilterDate(date);
    const filtered = appointments.filter((appt) =>
      date ? appt.appointmentDateTime.startsWith(date) : true
    );
    setFilteredAppointments(filtered);
    setCurrentPage(1);
  };

  const paginatedAppointments = filteredAppointments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredAppointments.length / itemsPerPage);

  return (
    <div className="bg-luxwhite p-4 shadow-lg rounded-lg">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      <h2 className="text-2xl font-bold text-darkgreen font-headers mb-4">
        Appointments
      </h2>

      <div className="mb-4 flex flex-col md:flex-row gap-4">
        <input
          type="text"
          placeholder="Search by patient..."
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          className="p-2 border rounded-md w-full md:w-auto"
        />
        <input
          type="date"
          value={filterDate}
          onChange={(e) => handleDateFilter(e.target.value)}
          className="p-2 border rounded-md w-full md:w-auto"
        />
      </div>

      {loading ? (
        <p>Loading appointments...</p>
      ) : (
        <div>
          <ul>
            {paginatedAppointments.map((appointment) => (
              <li
                key={appointment.appointment_id}
                className="border-b py-4 flex items-center justify-between"
              >
                <div>
                  <p className="mb-2">
                    <strong>Patient:</strong> {appointment.patientEmail}
                  </p>
                  
                  <p className="mb-2">
                    <span
                      className={`badge px-2 py-1 rounded-md ${
                        appointment.status === "completed"
                          ? "bg-green-200 text-green-800"
                          : "bg-yellow-200 text-yellow-800"
                      }`}
                    >
                      {appointment.status}
                    </span>
                  </p>
                  <p>
                    <span className="badge bg-gray-200 text-gray-800 px-2 py-1 rounded-md">
                      {new Date(appointment.appointmentDateTime).toLocaleDateString()}{" "}
                      {new Date(appointment.appointmentDateTime).toLocaleTimeString()}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleComplete(appointment.appointment_id)}
                    className="text-green-500 hover:text-green-700 flex items-center gap-1"
                  >
                    <FaCheck /> Mark Complete
                  </button>
                  <button
                    onClick={() => handleOpenRescheduleModal(appointment.appointment_id,user.id)}
                    className="text-blue-500 hover:text-blue-700 flex items-center gap-1"
                  >
                    <FaRedo /> Reschedule
                  </button>
                </div>
              </li>
            ))}
          </ul>


          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="flex justify-end mt-6">
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-lg shadow-md ${
                    currentPage === 1 ? "bg-gray-300 text-gray-600" : "bg-green-500 text-white hover:bg-green-600"
                  }`}
                >
                  Previous
                </button>
                <span className="text-sm font-medium">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-lg shadow-md ${
                    currentPage === totalPages ? "bg-gray-300 text-gray-600" : "bg-green-500 text-white hover:bg-green-600"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          )}

        </div>
      )}

      {showRescheduleModal && selectedAppointment && (
        <RescheduleModal
          appointmentId={selectedAppointment.appointmentId}
          doctorId={selectedAppointment.doctorId}
          onClose={handleCloseRescheduleModal}
          isOpen={true}
        />
      )}
    </div>
  );
};

export default AppointmentManagement;
